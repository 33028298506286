.trusted-partners {
  margin: 6rem 0 !important;
}
.trusted-by-partners-wrapper {
  text-align: center;
}
.trusted-by-partners-wrapper h2 {
  font-size: 3rem;
  color: #131313;
}
.partner-logo-images {
  flex-wrap: wrap;
  column-gap: 12rem;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  row-gap: 2rem;
}
.partner-logo-images img {
  width: 90px;
  opacity: 0.7;
}
.partner-logo-images img:hover {
  opacity: 1;
  transition: 0.3s;
}
@media (max-width: 768px) {
  .trusted-by-partners-wrapper {
    text-align: left;
  }
  .partner-logo-images {
    column-gap: 7rem;
    display: flex;
    gap: 40px;
  }
  .partner-logo-images img {
    width: 10rem;
  }
}
@media (max-width: 500px) {
  .partner-logo-images {
    justify-content: flex-start;
  }
}
