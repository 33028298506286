.about-us-section .section-heading {
  width: 76%;
}
.section-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9rem;
}
.image-container {
  width: 50%;
}
.features {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.feature {
  padding: 2rem 3rem;
  border: 1px solid #e3eeff;
  box-shadow: 4px 4px 7px 0px #6d65ff0a;
  border-radius: 22px;
}
.feature-heading {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.feature-description p {
  font-size: 1.4rem;
  color: #6c6c72;
}
@media (max-width: 1024px) {
  .image-container {
    width: 51%;
  }
  .features {
    width: 47%;
  }
}
@media (max-width: 768px) {
  .section-content-wrapper {
    flex-direction: column;
    gap: 4rem;
  }
  .image-container {
    width: 100%;
  }
  .features {
    width: 100%;
  }
  .about-us-section .section-heading {
    width: 100%;
  }
}
