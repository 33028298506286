.use-case-card-inner {
  padding: 2.5rem;
  min-height: 330px;
  border-radius: 22px;
  position: relative;
  margin-bottom: 5rem;
  transition: all 0.5s ease-in-out;
  cursor: default;
}
.use-case-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.5rem;
  padding: 0 2.5rem;
  transition: bottom 1s;
  z-index: 1;
}
.use-case-text h3 {
  font-size: 2.2rem;
  color: #fff;
}

.use-case-text p {
  color: #fff;
  opacity: 0;
  transition: opacity 0.8s;
  display: none;
}

.use-case-card-inner:hover .use-case-text {
  bottom: calc(100% - 12rem);
}
.use-case-card-inner:hover .use-case-text p {
  opacity: 1;
  display: block;
}
.use-case-card-inner::after {
  transition: all 0.5s ease-in-out;
  content: "";
  position: absolute;
  background: url(/src/assets/UseCasesSection/use-case-after-bg.svg) no-repeat
    center center / cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 22px;
}
.use-case-card-inner:hover::after {
  content: "";
  background: #101027a3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 22px;
}
.first-use-case {
  background: url(/src/assets/UseCasesSection/use-case-it-service.svg) no-repeat
    center center / cover;
}
.second-use-case {
  background: url(/src/assets/UseCasesSection/use-case-security-ops.svg)
    no-repeat center center / cover;
}
.third-use-case {
  background: url(/src/assets/UseCasesSection/use-case-customer.svg) no-repeat
    center center / cover;
}
.fourth-use-case {
  background: url(/src/assets/UseCasesSection/use-case-finance.svg) no-repeat
    center center / cover;
}
.fifth-use-case {
  background: url(/src/assets/UseCasesSection/use-case-supply-chain.svg)
    no-repeat center center / cover;
}
.sixth-use-case {
  background: url(/src/assets/UseCasesSection/use-case-hr.svg) no-repeat center
    center / cover;
}
.seventh-use-case {
  background: url(/src/assets/UseCasesSection/use-case-marketing.svg) no-repeat
    center center / cover;
}
.eighth-use-case {
  background: url(/src/assets/UseCasesSection/use-case-it-ops.svg) no-repeat
    center center / cover;
}
.ninth-use-case {
  background: url(/src/assets/UseCasesSection/use-case-threat.svg) no-repeat
    center center / cover;
}

@media (max-width: 1640px) {
  .use-case-card-inner:hover .use-case-text {
    bottom: calc(100% - 14rem);
  }
}

@media (max-width: 1440px) {
  .use-case-card-inner:hover .use-case-text {
    bottom: calc(100% - 17rem);
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .use-case-card-inner {
    min-height: 250px;
  }
  .use-case-card-inner:hover .use-case-text {
    bottom: calc(100% - 22rem);
  }
}
@media (max-width: 768px) {
  .use-case-card-inner:hover .use-case-text {
    bottom: calc(100% - 12rem);
  }
}
@media (max-width: 400px) {
  .use-case-card-inner:hover .use-case-text {
    bottom: calc(100% - 17rem);
  }
}
