.second-banner-section-wrapper {
  background: linear-gradient(165.98deg, #eff6ff 51%, #ceeaff 88.08%);
  border: 2px solid #b8d8ff;
  border-radius: 32px;
  padding: 8rem;
  position: relative;
}
.second-banner-section-wrapper::after {
  content: "";
  position: absolute;
  background: url(/src/assets/SecondBanner/second-banner-vector.svg);
  top: calc(100% - 171px);
  bottom: 0;
  right: 0;
  left: 0;
  background-repeat: no-repeat;
}

.second-banner-section-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.second-banner-section-heading h2 {
  margin-bottom: 3rem;
}
.second-banner-section-heading > p {
  margin: 0 auto;
  width: 68%;
}
.second-banner-section a {
  margin-top: 7rem;
}
.second-banner-button {
  border: none;
  background: #4d4dfe;
  border-radius: 46px;
  display: inline-block;
  box-shadow: 4px 4px 16px 0px #4d4dfe47;
  padding: 1rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
}
.second-banner-button p {
  margin: 0;
  font-size: 2.2rem;
  font-family: work-sans-medium;
  color: #ffffff;
}
@media (max-width: 1440px) {
  .second-banner-section-heading h2 {
    width: 90%;
  }
  .second-banner-section-heading > p {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .second-banner-section-heading h2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .second-banner-section-heading {
    text-align: left;
    align-items: flex-start;
  }
  .second-banner-section-wrapper {
    padding: 4rem;
  }
}
@media (max-width: 400px) {
  .second-banner-section-wrapper {
    padding: 4rem 2rem;
  }
  .second-banner-section-heading h2 {
    font-size: 2.8rem;
  }
  .second-banner-section-heading p {
    font-size: 13px;
  }
}
